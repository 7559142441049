import { FILTERS, POSTCARD_FORMAT_TYPE, POSTCARD_LAYOUT } from "@constants/app.enums";

/* eslint-disable no-magic-numbers */
export const REGEX = {
  EMAIL:
    /* eslint-disable-next-line max-len */
    /^[\p{L}\d!#$%&'*+=?^_`{|}~-]+(?:\.[\p{L}\d!#$%&'*+=?^_`{|}~-]+)*@(?:[_\p{L}\d][-_\p{L}\d]*\.)*(?:[\p{L}\d][-\p{L}\d]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,})$/iu,
  PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/,
  INTEGER: /^(?!.*e)\d*$/,
  DECIMAL: /^\d*\.?\d*$/,
  MOBILE:
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i
};

export const APP_ROUTES = {
  splashScreen: '',
  selectLayout: '/select-layout',
  editor: '/postcard-editor',
  filter: '/postcard-filter'
};

export const API_ROUTES = {


};

export const LAYOUT_OPTIONS = [
  { label: 'Landscape', value: POSTCARD_LAYOUT.HORIZONTAL },
  { label: 'Portrait', value: POSTCARD_LAYOUT.VERTICAL },
];

export const FORMAT_OPTIONS = [
  { label: 'One-page', value: POSTCARD_FORMAT_TYPE.ONE_PAGE },
  { label: 'Two-page', value: POSTCARD_FORMAT_TYPE.TWO_PAGE },
];

export const CANVAS_RATIO = 6 / 4;
export const VERTICAL_CANVAS_DIMENSIONS = {
  width: 740 / CANVAS_RATIO,
  height: 740
};

export const HORIZONTAL_CANVAS_DIMENSIONS = {
  width: 640 * CANVAS_RATIO,
  height: 640
};

export const FONT_FAMILY = ['typewriter', 'handwriting'];

export const COLOR_LIST = [{
  name: 'black',
  value: '#000000',
  classes: 'bg-black'
}, {
  name: 'dark-blue',
  value: '#00008B',
  classes: 'bg-dark-blue'
}, {
  name: 'red',
  value: '#8B0000',
  classes: 'bg-custom-red'
}];


export const FILTERS_LIST = [
  { label: 'Vintage', value: FILTERS.VINTAGE },
  { label: 'Sepia', value: FILTERS.SEPIA },
  { label: 'B/W', value: FILTERS.BLACK_AND_WHITE },
  { label: 'Kodachrome', value: FILTERS.KODACHROME },
  { label: 'Polaroid', value: FILTERS.POLAROID },
  { label: 'Technicolor', value: FILTERS.TECHNICOLOR },
  { label: 'Resize', value: FILTERS.RESIZE },
];

export const DEFAULT_BACKGROUND_IMAGE_URL = 'assets/images/default_bg.webp';

export const MAP_URL = 'https://map-india.org/';

export const MAP_STAMP_LOGO = 'assets/images/map-stamp.svg';
export const DELETE_CONTROL_ICON = 'assets/images/delete-icon.svg';
export const ROTATE_CONTROL_ICON = 'assets/images/rotate-icon.svg';
export const RESIZE_CONTROL_ICON = 'assets/images/resize.svg';
export const INACTIVITY_TIMEOUT = 600000;
export const SPLASH_SCREEN_TIMEOUT = 3000;
export const IDLE_VIDEO_URL = 'assets/videos/idle_video.mp4';
export const MIN_FONT_SIZE = 1;
export const MAX_FONT_SIZE = 200;
export const SPACE_BETWEEN_TWO_POSTCARD_SIDE = 20;

export const DEFAULT_CANVAS_CONFIG: fabric.ICanvasOptions = {
  backgroundColor: '#FDF3D3',
  allowTouchScrolling: false,
  selection: false,
  enableRetinaScaling: true,
  renderOnAddRemove: true,
};

export const TEXT_CONTROLS_VISIBILITY = {
  bl: false,
  mb: false,
  ml: false,
  br: false,
  mr: true,
  mt: false,
  tl: false,
  tr: false,
  mtr: false
};

export const DEFAULT_CONTROLS_VISIBILITY = {
  bl: false,
  mb: false,
  ml: false,
  br: false,
  mr: false,
  mt: false,
  tl: false,
  tr: false,
  mtr: false
};

export const POINTER_SIZE = {
  MAX: 20,
  MIN: 1
}