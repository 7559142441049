export enum REGEX_TYPE {
  DECIMAL = 'decimal',
  INTEGER = 'integer'
}

export enum POSITION {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum POSTCARD_LAYOUT {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export enum POSTCARD_FORMAT_TYPE {
  ONE_PAGE = 'one-page',
  TWO_PAGE = 'two-page'
}

export enum FILTERS {
  BLACK_AND_WHITE = 'black-and-white',
  VINTAGE = 'vintage',
  SEPIA = 'sepia',
  POLAROID = 'polaroid',
  TECHNICOLOR = 'technicolor',
  KODACHROME = 'kodachrome',
  RESIZE = 'resize'
}

export enum TEXT_STYLE {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline'
}

export enum TEXT_ALIGNMENT {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum MENU_OPTIONS {
  TEXT = 'text',
  BACKGROUND = 'background',
  ELEMENTS = 'elements',
  FILTERS = 'filters',
  DRAW = 'draw'
}

export enum IMAGE_PROGRESS_STATE {
  INIT = 'init',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error'
}
